<template>
  <div ref="container" class="collect">
    <mt-navbar class="page-part" v-model="selected">
      <mt-tab-item id="1">
        <span @click="lessonEvent">课程</span>
      </mt-tab-item>
      <mt-tab-item id="2">
        <span @click="findEvent">发现</span>        
      </mt-tab-item>
      <mt-tab-item id="3">商品</mt-tab-item>
    </mt-navbar>

    <mt-tab-container v-model="selected">
      <mt-tab-container-item id="1">
        <div class="scroll-container" v-if="lessonList.length > 0">
          <ul
            class="lesson-list"
            v-infinite-scroll="lessonEvent"
            infinite-scroll-disabled="Has"
            infinite-scroll-distance="10"
          >
            <li v-for="(item, index) in lessonList" :key="index">
              <div class="card">
                <router-link
                  :to="item.isoffline == 1 ? `/lesson/offline/${item.pid}`: `/lesson/onlineDetail/${item.pid}`"
                >
                  <div class="container">
                    <img v-lazy="item.images" />
                    <div class="info">
                      <h1>
                        {{ item.bookname }}
                        <br />
                      </h1>
                      <p class="gold-num">￥{{ item.price }}</p>
                    </div>
                  </div>
                </router-link>
              </div>
            </li>
          </ul>
        </div>
        <div v-else class="emptyData" v-show="initLoading">
          <img src="@assets/images/emptyData.png" />
          <p>暂无数据</p>
        </div>
        <Loading :loaded="loadendLesson" :loading="loadingLesson"></Loading>
      </mt-tab-container-item>
      <mt-tab-container-item id="2">
        <div class="scroll-container" v-if="findList.length > 0">
          <ul
            class="lesson-list"
            v-infinite-scroll="findEvent"
            infinite-scroll-disabled="Has"
            infinite-scroll-distance="10"
          >
            <li v-for="(item, index) in findList" :key="index">
              <div class="find">
                <router-link
                  :to="`/community/detail/${item.id}`"
                >
                  <div class="find-contaienr">
                    <div class="head">
                      <img :src="item.avatar">
                      <div>
                        <p><b>{{ item.nickname }}</b></p>
                        <p><time>{{ item.create_time }}</time></p>
                      </div>
                    </div>
                    <div class="text">
                      {{ item.content }}
                    </div>
                  </div>
                </router-link>
              </div>
            </li>
          </ul>
        </div>
        <div v-else class="emptyData">
          <img src="@assets/images/emptyData.png" />
          <p>暂无数据</p>
        </div>
        <Loading :loaded="loadendLesson" :loading="loadingLesson"></Loading>
      </mt-tab-container-item>
      <mt-tab-container-item id="3">
        <div class="collectionGoods" v-if="collectProductList.length > 0">
          <router-link
            :to="{ path: '/detail/' + item.pid }"
            class="item acea-row row-between-wrapper"
            v-for="(item, index) in collectProductList"
            :key="index"
          >
            <div class="pictrue">
              <img v-lazy="item.image" />
            </div>
            <div class="text acea-row row-column-between">
              <div class="infor line1">{{ item.store_name }}</div>
              <div class="acea-row row-between-wrapper">
                <div class="money font-color-red">￥{{ item.price }}</div>
                <div class="delete" @click.prevent="delCollection(index)">删除</div>
              </div>
            </div>
          </router-link>
        </div>
        <Loading :loaded="loadend" :loading="loading"></Loading>
        <div
          class="noCommodity"
          style="background-color:#fff;"
          v-if="collectProductList.length < 1 && page > 1"
        >
          <div class="noPictrue">
            <img src="@assets/images/noShopper.png" class="image" />
          </div>
          <Recommend></Recommend>
        </div>
      </mt-tab-container-item>
    </mt-tab-container>
  </div>
</template>

<script>
import Recommend from "@components/Recommend";
import { getCollectUser, getCollectDel, communityCollect } from "@api/user";
import Loading from "@components/Loading";
export default {
  name: "GoodsCollection",
  components: {
    Recommend,
    Loading
  },
  props: {},
  data: function() {
    return {
      page: 1,
      pageLesson: 1,
      pageFind: 1,
      limit: 10,
      lessonList: [],
      findList: [],
      collectProductList: [],
      loadTitle: "",
      loadend: false,
      selected: "1",
      loading: false,
      isLoading: false,
      loadingLesson: false,
      loadingFind: false,
      initLoading: false,
      loadendFind: false
    };
  },
  mounted: function() {
    this.lessonEvent();
    this.findEvent();
    this.get_user_collect_product();
    this.$scroll(this.$refs.container, () => {
      !this.loading && this.get_user_collect_product();
    });
    if(sessionStorage.getItem('collectionIndex')) {
      this.selected = sessionStorage.getItem('collectionIndex');
    }
  },
  watch: {
    'selected'(n) {
      sessionStorage.setItem('collectionIndex', n)
    }
  },
  methods: {
    // 获取课程收藏
    lessonEvent() {
      let that = this;

      if (that.loadingLesson) return; //阻止下次请求（false可以进行请求）；
      if (that.loadendLesson) return; //阻止结束当前请求（false可以进行请求）；
      that.loadingLesson = true;
      getCollectUser(that.pageLesson, that.limit, "lesson").then(res => {
        that.loadingLesson = false;
        //apply();js将一个数组插入另一个数组;
        that.lessonList.push.apply(that.lessonList, res.data);
        that.loadendLesson = res.data.length < that.limit; //判断所有数据是否加载完成；
        that.pageLesson = that.pageLesson + 1;
        that.initLoading = true
      });
    },

    // 获取发现收藏
    findEvent() {
      let that = this;
      if (that.loadingFind) return; //阻止下次请求（false可以进行请求）；
      if (that.loadendFind) return; //阻止结束当前请求（false可以进行请求）；
      that.loadingFind = true;
      communityCollect(that.pageFind, that.limit, 'community').then(res => {
        that.loadingFind = false;
        //apply();js将一个数组插入另一个数组;
        that.findList.push.apply(that.findList, res.data);
        that.loadendFind = res.data.length < that.limit; //判断所有数据是否加载完成；
        that.pageFind = that.pageFind + 1;
      });
    },

    get_user_collect_product: function() {
      let that = this;
      if (that.loading) return; //阻止下次请求（false可以进行请求）；
      if (that.loadend) return; //阻止结束当前请求（false可以进行请求）；
      that.loading = true;
      getCollectUser(that.page, that.limit, 'product').then(res => {
        that.loading = false;
        //apply();js将一个数组插入另一个数组;
        that.collectProductList.push.apply(that.collectProductList, res.data);
        that.loadend = res.data.length < that.limit; //判断所有数据是否加载完成；
        that.page = that.page + 1;
      });
    },
    //删除收藏；
    delCollection: function(index) {
      let that = this,
        id = that.collectProductList[index].pid,
        category = that.collectProductList[index].category;
      getCollectDel(id, category).then(function() {
        that.$dialog.toast({
          mes: "删除收藏成功!",
          callback: () => {
            that.collectProductList.splice(index, 1);
            that.$set(that, "collectProductList", that.collectProductList);
          }
        });
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.collect {
  background: white;
  .mint-tab-container {
    margin-top: .1rem;
  .container {
    padding: 0.3rem;
    display: flex;
    justify-content: space-between;
    height: 2.6rem;
    border-top: 1px solid #eee;
    img {
      width: 50%;
      height: 100%;
      background: black;
      border-radius: 0.04rem 0 0 0.04rem;
    }
    .info {
      width: 50%;
      padding: 0.1rem 0.2rem;
      display: flex;
      flex-flow: column nowrap;
      justify-content: space-between;
      box-shadow: 0 0.02rem 0.06rem #dddddd;
      border-radius: 0 0.04rem 0.04rem 0;
      h1 {
        font-size: 0.26rem;
        font-weight: 500;
        overflow: hidden;
        line-height: 0.36rem;
        max-height: 1.08rem;
        span {
          font-weight: 500;
          font-size: 0.24rem;
        }
      }
    }
  }
  .find {
    padding: 0.3rem;
    display: flex;
    justify-content: space-between;
    border-top: 1px solid #eee;
    .head {
      display: flex;
      justify-content: flex-start;
      margin-bottom: .2rem;
      img {
        width: .72rem;
        height: .72rem;
        border-radius: 50%;
        margin-right: .2rem;
      }
      time {
        font-size: .26rem;
        color: #888;
        margin-top: .1rem;
      }
    }
    .text {
      font-size: .28rem;
      line-height: .4rem;
      max-height: .8rem;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
    }
  }
  }
}
</style>